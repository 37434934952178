import React from "react";
import { Link } from "react-router-dom";
import "./RelatedArticles.css";

function RelatedArticles({ articles }) {
  return (
    <div className="related-articles">
      <h2>Ces articles peuvent aussi vous intéresser</h2>
      <div className="articles-grid">
        {articles.slice(0, 3).map((article) => (
          <div key={article.id} className="article-item">
            {article.attributes.Image && (
              <img
                src={`${process.env.REACT_APP_STRAPI_URL}${article.attributes.Image.data.attributes.url}`}
                alt={article.attributes.Titre}
                className="article-image"
              />
            )}
            <h3>{article.attributes.Titre}</h3>
            <p>
              {article.attributes.SousTitre ||
                article.attributes.Contenu.substring(0, 100)}
              ...
            </p>

            <div className="right">
              <Link
                to={`/blog/${article.attributes.URL}`}
                className="read-more"
              >
                Voir l'article <span>→</span>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default RelatedArticles;

import { useEffect, useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import GoogleConnect from "../../components/GoogleConnect.js";
import mixpanel from "mixpanel-browser";

import { AuthContext } from "../../context/AuthContext.js";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./ExtensionLinkedIn.css";
import "../../i18n";

function ExtensionLinkedIn() {
  const { t } = useTranslation();
  const context = useContext(AuthContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [linkedinCookies, setLinkedinCookies] = useState([]);
  const [liAtCookie, setLiAtCookie] = useState(null);
  const [userAgent, setUserAgent] = useState("");
  const [loginSuccess, setLoginSuccess] = useState(false);

  const generateRandomUserAgent = () => {
    const userAgents = [
      "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.159 Safari/537.36",
      "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/14.0.3 Safari/605.1.15",
    ];
    const randomIndex = Math.floor(Math.random() * userAgents.length);
    return userAgents[randomIndex];
  };

  // Déclarez handleSyncCookies avant son utilisation dans onMessage listener
  const handleSyncCookies = () => {
    const token = localStorage.getItem("Token");
    const userId = context.userInfos?.id;
    const userAgentParam = userAgent || generateRandomUserAgent();

    if (token && linkedinCookies.length > 0 && userAgentParam && userId) {
      const cookiesToSend = linkedinCookies.map((cookie) => {
        // Correction de sameSite ici aussi si nécessaire
        const sameSiteValue =
          cookie.sameSite === "no_restriction" ? "None" : cookie.sameSite;

        return {
          name: cookie.name,
          value: cookie.value,
          domain: cookie.domain,
          expirationDate: cookie.expirationDate,
          hostOnly: cookie.hostOnly,
          httpOnly: cookie.httpOnly,
          path: cookie.path,
          sameSite: sameSiteValue,
          secure: cookie.secure,
          storeId: cookie.storeId,
        };
      });

      fetch(
        process.env.REACT_APP_BASEURL + "/api/user/update-linkedin-cookie",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({
            userId,
            cookies: cookiesToSend,
            userAgent: userAgentParam,
          }),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          // console.log("Réponse du serveur:", data);
          toast.success("Cookies LinkedIn synchronisés avec succès !");
        })
        .catch((error) => {
          console.error(
            "Erreur lors de la synchronisation des cookies:",
            error
          );
          toast.error("Erreur lors de la synchronisation des cookies.");
        });
    } else {
      console.error(
        "Informations manquantes pour la synchronisation des cookies."
      );
      toast.error(
        "Informations manquantes pour la synchronisation des cookies."
      );
    }
  };

  useEffect(() => {
    const handleCookiesMessage = (event) => {
      if (event.data.action === "linkedinCookies") {
        // console.log("Cookies LinkedIn reçus:", event.data.cookies);
        setLinkedinCookies(event.data.cookies);
        event.data.cookies.forEach((cookie) => {
          if (cookie.name === "li_at") setLiAtCookie(cookie.value);
        });
        setUserAgent(event.data.userAgent);
      } else if (event.data.action === "liAtCookie") {
        // console.log("Cookie li_at reçu:", event.data.li_at);
        setLiAtCookie(event.data.li_at);
        setUserAgent(event.data.userAgent);
      } else if (event.data.action === "liAtCookieUpdated") {
        // console.log("Cookie li_at mis à jour:", event.data.li_at);
        setLiAtCookie(event.data.li_at);
        handleSyncCookies(event.data.li_at, userAgent);
      } else if (event.data.action === "cookieUpdated") {
        const updatedCookie = event.data.cookie;
        // console.log("Cookie mis à jour reçu:", updatedCookie);
        setLinkedinCookies((prevCookies) => {
          return prevCookies.map((cookie) =>
            cookie.name === updatedCookie.name ? updatedCookie : cookie
          );
        });
      }
    };

    window.addEventListener("message", handleCookiesMessage);

    const token = localStorage.getItem("Token");
    if (token) {
      context.setToken(token);
      context.setIsAuthenticate(true);
    }
    context.setIsFromExtension(true);

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("authenticated") === "true") {
      setLoginSuccess(true);
    }

    if (context.setIsAuthenticate) {
      setLoginSuccess(true);
    }

    // Assurez-vous d'envoyer l'action pour récupérer tous les cookies, pas seulement li_at
    window.parent.postMessage({ action: "getLinkedInCookies" }, "*");

    return () => {
      window.removeEventListener("message", handleCookiesMessage);
    };
  }, [context, userAgent]);

  const handleOpenModal = () => {
    setIsVisible(true);
  };

  const handleCloseModal = () => {
    setIsVisible(false);
  };

  const handleLogin = (e) => {
    e.preventDefault();

    const lowercaseEmail = email.toLowerCase();

    fetch(process.env.REACT_APP_BASEURL + "/api/user/login", {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        email: lowercaseEmail,
        password,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          localStorage.setItem("Token", res.token);

          context.setUserInfos({
            email: res.email,
            userId: res.userId,
          });
          context.setIsAuthenticate(true);
          context.setToken(res.token);
          context.setTeamId(res.teamId);
          mixpanel.identify(res.teamId);
          mixpanel.people.set({
            $email: res.email,
            $firstName: res.firstName,
            $lastName: res.surname,
            "Last Login": new Date(),
          });
          mixpanel.track("Login via Extension LinkedIn");

          setLoginSuccess(true);

          window.postMessage({ action: "getLinkedInCookies" }, "*");

          window.parent.postMessage(
            {
              action: "saveToken",
              token: res.token,
              userId: res.userId,
            },
            "*"
          );
        } else {
          if (res.msg === "Account not confirmed, check your e-mail !") {
            setLoginError(t("account_not_confirmed"));
          } else {
            setLoginError(t("bad_credentials"));
          }
        }
      });
  };

  const handleGoogleLoginSuccess = (res) => {
    localStorage.setItem("Token", res.token);

    context.setUserInfos({
      email: res.email,
      userId: res.userId,
    });
    context.setIsAuthenticate(true);
    context.setToken(res.token);
    context.setTeamId(res.teamId);
    mixpanel.identify(res.teamId);
    mixpanel.people.set({
      $email: res.email,
      $firstName: res.firstName,
      $lastName: res.surname,
      "Last Login": new Date(),
    });
    mixpanel.track("Login via Extension LinkedIn");

    setLoginSuccess(true);

    window.postMessage({ action: "getLinkedInCookies" }, "*");

    window.parent.postMessage(
      {
        action: "saveToken",
        token: res.token,
      },
      "*"
    );
  };

  // const handleSyncCookies = (liAtCookieParam, userAgentParam) => {
  //   console.log("Tentative de synchronisation du cookie:", liAtCookieParam);
  //   const token = localStorage.getItem("Token");
  //   const userId = context.userInfos.id;

  //   if (token && liAtCookieParam && userAgentParam && userId) {
  //     fetch(
  //       process.env.REACT_APP_BASEURL + "/api/user/update-linkedin-cookie",
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: "Bearer " + token,
  //         },
  //         body: JSON.stringify({
  //           userId,
  //           cookie: liAtCookieParam,
  //           userAgent: userAgentParam,
  //         }),
  //       }
  //     )
  //       .then((response) => {
  //         if (!response.ok) {
  //           throw new Error(
  //             "Erreur lors de la requête: " + response.statusText
  //           );
  //         }
  //         return response.json();
  //       })
  //       .then((data) => {
  //         console.log("Réponse du serveur pour la synchronisation:", data);
  //         toast.success("Cookie LinkedIn synchronisé avec succès !");
  //       })
  //       .catch((error) => {
  //         console.error("Erreur lors de la synchronisation du cookie:", error);
  //         toast.error("Erreur lors de la synchronisation du cookie.");
  //       });
  //   } else {
  //     console.error(
  //       "Informations manquantes pour la synchronisation des cookies."
  //     );
  //     toast.error(
  //       "Informations manquantes pour la synchronisation des cookies."
  //     );
  //   }
  // };

  return (
    <>
      <div className="fullContainer">
        <div className="card">
          <div className="loginContainer">
            <div className="rightSide">
              <main className="">
                <div className="loginWrap loginPage">
                  <a>
                    <img className="logoLogin" src="/logo.svg" />
                  </a>
                  {context.isAuthenticate ? (
                    <div>
                      <br />
                      <div>
                        <button
                          onClick={handleSyncCookies}
                          className="ctaPrimaryBlack"
                          style={{ width: "100%", height: "40px" }}
                        >
                          {t("Synchronize_LinkedIn_cookie")}
                        </button>
                        <br />
                        <br />
                        <span className="smallText">
                          {t(
                            "by_clicking_here_you_allowed_buska_to_use_your_info"
                          )}
                        </span>
                      </div>
                    </div>
                  ) : (
                    <>
                      <p>{t("never_miss_any_mention_of_your_brand_again")}</p>
                      <GoogleConnect
                        className="connectGoogleButton"
                        buttonText={t("Continue with Google")}
                        label="Continue with Google"
                        methodeType="login"
                        isExtension={true}
                        onSuccess={handleGoogleLoginSuccess}
                      />
                      <br />
                      <br />
                      <span className="lineLogin">or</span>
                      <br />
                      <br />
                      <form onSubmit={handleLogin}>
                        <div className={`form-group`}>
                          <input
                            placeholder="elon@spacex.com"
                            type="email"
                            id="email"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        <div className={`form-group`}>
                          <input
                            placeholder={t("password")}
                            type="password"
                            id="password"
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                        {loginError && (
                          <div className="error-message">{loginError}</div>
                        )}

                        <button
                          type="submit"
                          className="ctaPrimaryBlack"
                          style={{ width: "100%", height: "40px" }}
                        >
                          {t("connexion")}
                        </button>
                        <br />
                      </form>
                      <br />
                    </>
                  )}
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ExtensionLinkedIn;

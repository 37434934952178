import { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext();

export default ({ children }) => {
  const navigate = useNavigate();
  const [isAuthenticate, setIsAuthenticate] = useState(false);
  const [initialLogin, setInitialLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userInfos, setUserInfos] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("Token") || "");
  const [teamId, setTeamId] = useState(
    localStorage.getItem("selectedTeamId") || ""
  );
  const [teamDetails, setTeamDetails] = useState(null);
  // );
  const [keyword, setKeyword] = useState("");

  const disconnect = () => {
    setToken("");
    setIsAuthenticate(false);
    setUserInfos(null);
    setTeamId(null);
    setInitialLogin(true);
    navigate("/");
  };

  // useEffect(() => {
  //   setIsLoading(true);
  //   localStorage.setItem("Token", token);
  //   const headers = {};

  //   if (token) {
  //     headers.Authorization = `Bearer ${token}`;
  //     fetch(process.env.REACT_APP_BASEURL + "/api/user/profile", {
  //       headers: new Headers(headers),
  //       method: "GET",
  //     })
  //       .then((res) => res.json())
  //       .then((res) => {
  //         if (res.success) {
  //           setIsAuthenticate(true);
  //           setUserInfos({
  //             id: res.user._id,
  //             email: res.user.email,
  //             image: res.user.image,
  //             onboarding: res.user.onboarding,
  //             firstname: res.user.firstname,
  //             surname: res.user.surname,
  //             referal: res.user.affiliateCode,
  //             isPremium: res.user.isPremium,
  //             premiumPlan: res.user.premiumPlan,
  //             linkedinCookieActivate: res.user.linkedinCookieActivate,
  //           });

  //           // Récupérer les équipes de l'utilisateur
  //           fetch(
  //             process.env.REACT_APP_BASEURL + `/api/teams/user/${res.user._id}`,
  //             {
  //               headers: new Headers(headers),
  //               method: "GET",
  //             }
  //           )
  //             .then((teamRes) => teamRes.json())
  //             .then((teamData) => {
  //               if (teamData.success && teamData.teams.length > 0) {
  //                 const defaultTeam = teamData.teams[0];
  //                 const defaultTeamId = teamData.teams[0]._id;
  //                 setTeamId(defaultTeamId);
  //                 setTeamDetails(defaultTeam);

  //                 // Vérifier si les mots-clés existent et sont un tableau non vide
  //                 if (defaultTeam.keyword && defaultTeam.keyword.length > 0) {
  //                   setKeyword(defaultTeam.keyword[0].keywordName);
  //                 } else {
  //                   setKeyword(""); // Optionnel: définir un mot-clé par défaut ou laisser vide
  //                 }
  //               }
  //               setIsLoading(false); // Déplacer ici pour s'assurer que le chargement se termine après la récupération de l'équipe
  //             })
  //             .catch((err) => {
  //               console.error("Error fetching teams:", err);
  //               setIsLoading(false); // S'assurer que le chargement est terminé même en cas d'erreur
  //             });
  //         } else {
  //           console.error("Error during authentication:", res);
  //           setIsLoading(false);
  //         }
  //       })
  //       .catch((err) => {
  //         console.error("Network error:", err);
  //         setIsLoading(false);
  //       });
  //   } else {
  //     setIsLoading(false);
  //   }
  // }, [token]);

  useEffect(() => {
    if (localStorage.getItem("Token")) {
      setToken(localStorage.getItem("Token"));
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        token,
        setToken,
        userInfos,
        setUserInfos,
        disconnect,
        isAuthenticate,
        setIsAuthenticate,
        teamId,
        setTeamId,
        teamDetails,
        setTeamDetails,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

import axios from "axios";

import "./footer.css";

const Footer = () => {
  const [content, setContent] = useState(null);

  const contents = `${process.env.REACT_APP_STRAPI_URL}`;

  useEffect(() => {
    axios
      .get(`${contents}/api/footers?populate=*`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_BEARER_CONTENT}`,
        },
      })
      .then((response) => {
        setContent(response.data.data[0].attributes);
      })
      .catch((error) => {
        console.error("Il y a eu une erreur !", error);
      });
  }, []);

  if (!content) {
    return <div>Loading...</div>;
  }

  return (
    <footer id="globalFooter">
      <div className="wrap footer-content">
        <div className="footer-section">
          <img
            width="180"
            className="footerLogo"
            src={`${contents}${content.LogoFooter.data.attributes.url}`}
            alt={
              content.LogoFooter.data.attributes.alternativeText || "ANDA logo"
            }
          />
          <p className="footer-description">{content.ContentFooter}</p>
          <div className="social-icons">
            <a
              href="https://www.instagram.com"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa-brands fa-instagram"></i>
            </a>
            <a href="https://www.facebook.com" target="_blank" rel="noreferrer">
              <i className="fa-brands fa-facebook"></i>
            </a>
            <a href="https://www.tiktok.com" target="_blank" rel="noreferrer">
              <i className="fa-brands fa-tiktok"></i>
            </a>
          </div>
        </div>
        <div className="footer-section">
          <ul>
            <li>
              <Link to="/">Anima Dance</Link>
            </li>
            <li>
              <Link to="/qui-est-anda">Qui est AN-DA ?</Link>
            </li>
            <li>
              <Link to="/application">L'application</Link>
            </li>
            <li>
              <Link to="/blog">Blog</Link>
            </li>
          </ul>
        </div>
        <div className="footer-section">
          <h2>Ne ratez aucune nouveauté !</h2>
          <div className="newsletter-signup">
            <input
              type="email"
              placeholder="Entrez votre e-mail"
              aria-label="Entrez votre e-mail"
            />
            <button type="submit">
              <i className="fa fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
      <div className="wrap subFooter">
        <div className="footer-left">
          <p>© AN-DA 2024 - Tous droits réservés</p>
        </div>
        <div className="footer-right">
          {/* <a href="/cgu">CGU</a> |
          <a href="/mentions-legales">Mentions légales</a> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;

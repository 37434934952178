import React from "react";
import { Link } from "react-router-dom";
import "./ArticleGrid.css"; // Crée un fichier CSS pour la grille

function ArticleGrid({ articles, currentPage, totalPages, onPageChange }) {
  return (
    <div className="article-grid-section">
      <div className="articles-gridBlog">
        {articles.map((article) => (
          <div key={article.id} className="blog-card">
            {article.attributes.Image && (
              <img
                src={`${process.env.REACT_APP_STRAPI_URL}${article.attributes.Image.data.attributes.url}`}
                alt={article.attributes.Titre}
                className="blog-image"
              />
            )}
            <h3 className="blog-title">{article.attributes.Titre}</h3>
            <p className="blog-excerpt">
              {article.attributes.SousTitre ||
                article.attributes.Contenu.substring(0, 100)}
              ...
            </p>
            <div className="right">
              <Link
                to={`/blog/${article.attributes.URL}`}
                className="blog-link"
              >
                Voir l'article <span>→</span>
              </Link>
            </div>
          </div>
        ))}
      </div>

      {/* Pagination Controls */}
      <div className="pagination-controls">
        <button
          disabled={currentPage === 1}
          onClick={() => onPageChange(currentPage - 1)}
        >
          Précédent
        </button>
        {[...Array(totalPages)].map((_, i) => (
          <button
            key={i + 1}
            className={i + 1 === currentPage ? "active" : ""}
            onClick={() => onPageChange(i + 1)}
          >
            {i + 1}
          </button>
        ))}
        <button
          disabled={currentPage === totalPages}
          onClick={() => onPageChange(currentPage + 1)}
        >
          Suivant
        </button>
      </div>
    </div>
  );
}

export default ArticleGrid;

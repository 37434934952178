// REACT STUFF
import React, { Suspense } from "react";
import { useEffect, useState, useContext, Component } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import axios from "axios";

// AUTH CONTEXT
import { AuthContext } from "../context/AuthContext.js";

import GoogleConnect from "../components/GoogleConnect.js";

// LIBS
import { useTranslation } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import "../i18n.js";
import Modal from "react-modal";

//Components
const Footer = React.lazy(() => import("../components/footer.js"));
import OurBlog from "../components/OurBlog.js";
import ArticleGrid from "../components/ArticleGrid.js";

import Loader from "../components/Loader.js";
const Accordion = React.lazy(() => import("../components/Accordion.js"));
import TypingText from "../components/typingText.js";

// LIB
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// CSS STYLES
import "./Blog.css";
const Testimonials = React.lazy(() => import("../components/Testimonials.js"));

function QuiEstAnda() {
  const context = useContext(AuthContext);
  const [content, setContent] = useState(null);
  const [recentArticles, setRecentArticles] = useState([]);
  const [allArticles, setAllArticles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const articlesPerPage = 6;
  const contents = `${process.env.REACT_APP_STRAPI_URL}`;

  const { t, i18n } = useTranslation();

  useEffect(() => {
    axios
      .get(`${contents}/api/about-us?populate=*`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_BEARER_CONTENT}`,
        },
      })
      .then((response) => {
        setContent(response.data.data.attributes);
      })
      .catch((error) => {
        console.error("Il y a eu une erreur !", error);
      });

    // Récupérer les articles récents (limite à 3 articles)
    axios
      .get(`${contents}/api/blogs?populate=*&pagination[limit]=3`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_BEARER_CONTENT}`,
        },
      })
      .then((response) => {
        setRecentArticles(response.data.data);
      })
      .catch((error) => {
        console.error("Erreur lors du chargement des articles récents", error);
      });

    // Récupérer tous les articles avec pagination
    loadArticles(currentPage);
  }, [currentPage]);

  const loadArticles = (page) => {
    axios
      .get(
        `${contents}/api/blogs?populate=*&pagination[page]=${page}&pagination[pageSize]=${articlesPerPage}`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_BEARER_CONTENT}`,
          },
        }
      )
      .then((response) => {
        setAllArticles(response.data.data);
        setTotalPages(response.data.meta.pagination.pageCount);
      })
      .catch((error) => {
        console.error("Erreur lors du chargement des articles", error);
      });
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  if (!content) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {/* <LoginModal isOpen={isOpenLogin} hide={toggleModalLogin} /> */}

      <div className="globalContainer">
        <main id="BlogSection">
          <div className="mainBgAda">
            <div></div>
          </div>
          <div className="wrap vac">
            <h1 className="mb50">
              {/* <span dangerouslySetInnerHTML={{ __html: content.Title }}></span> */}
              <img className="leftFill" src="/assets/left_fill.png" />
              <span>Blog</span>
              <img className="rightFill" src="/assets/right_fill.png" />
            </h1>
            <h4>
              Découvrez les dernières tendances, conseils et récits inspirants
              du monde vibrant de la danse
            </h4>
            <br />
            <br />
          </div>
        </main>

        {/* Section Articles Récents */}
        <section id="recentArticles">
          <h2>Articles récents</h2>
          <OurBlog articles={recentArticles} />
        </section>

        {/* Section Tous les Articles */}
        <section id="allArticles">
          <h2>Tous les articles</h2>
          <ArticleGrid
            articles={allArticles}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </section>

        <section id="highlightEventAda">
          <div className="highlight-contentAda">
            <h2 className="highlight-title">{content.VignetteTitle}</h2>
            <p
              className="small-text"
              dangerouslySetInnerHTML={{ __html: content.VignetteDescription }}
            ></p>
            <div className="buttonStoreAda">
              {content.appleButton && content.appleButton.data && (
                <a target="_blank" href={content.buttonAppleStore}>
                  <img
                    className="buttonStore"
                    src={`${contents}${content.appleButton.data.attributes.url}`}
                    alt="Apple Store"
                  />
                </a>
              )}

              {content.GooglePlayButton && content.GooglePlayButton.data && (
                <a target="_blank" href={content.buttonGooglePlay}>
                  <img
                    className="buttonStore"
                    src={`${contents}${content.GooglePlayButton.data.attributes.url}`}
                    alt="Google Play"
                  />
                </a>
              )}
            </div>
          </div>
          <div>
            {content.Phone && content.Phone.data && (
              <img
                className="phoneHHighlight"
                src={`${contents}${content.Phone.data.attributes.url}`}
                alt="Iphone Appercu"
              />
            )}
          </div>
        </section>

        <Suspense fallback={<div>{t("loading")}...</div>}>
          <Footer />
        </Suspense>
      </div>
    </>
  );
} // END HOMEPAGE

export default QuiEstAnda;

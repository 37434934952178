// REACT STUFF
import React, { Suspense } from "react";
import { useEffect, useState, useContext, Component } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import axios from "axios";

// AUTH CONTEXT
import { AuthContext } from "../context/AuthContext.js";

import GoogleConnect from "../components/GoogleConnect.js";

// LIBS
import { useTranslation } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import "../i18n.js";
import Modal from "react-modal";

//Components
const Footer = React.lazy(() => import("../components/footer.js"));

import Loader from "../components/Loader.js";
const Accordion = React.lazy(() => import("../components/Accordion.js"));
import TypingText from "../components/typingText.js";

// LIB
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// CSS STYLES
import "./Application.css";
const Testimonials = React.lazy(() => import("../components/Testimonials.js"));

function Application() {
  const context = useContext(AuthContext);

  const [isOpenLogin, setIsOpenLogin] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [results, setResults] = useState(null);
  const [resultsLinkedin, setResultsLinkedin] = useState(null);
  const [resultsReddit, setResultsReddit] = useState(null);
  const [resultsPinterest, setResultsPinterest] = useState(null);
  const [resultsYoutube, setResultsYoutube] = useState(null);
  const [resultsTiktok, setResultsTiktok] = useState(null);
  const [resultsInstagram, setResultsInstagram] = useState(null);
  const [resultsGoogle, setResultsGoogle] = useState(null);
  const [resultsGoogleNews, setResultsGoogleNews] = useState(null);
  const [resultsMedium, setResultsMedium] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isToggled, setIsToggled] = useState(false);
  const [isMonthly, setIsMonthly] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const { t, i18n } = useTranslation();

  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };

  const redirectToRegister = () => {
    window.location.href = "/register";
  };

  const openModalWithContent = (content) => {
    setModalContent(content);
    openModal();
  };

  const toggleModalLogin = () => {
    setIsOpenLogin(!isOpenLogin);
  };

  const handleToggle = () => {
    setIsMonthly(!isMonthly);
  };

  const formatCount = (count) => {
    if (count <= 100) {
      return count.toString();
    } else if ((count) => 1000) {
      return 100;
    }
  };

  const [content, setContent] = useState(null);
  const contents = `${process.env.REACT_APP_STRAPI_URL}`;

  useEffect(() => {
    axios
      .get(`${contents}/api/app?populate=*`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_BEARER_CONTENT}`,
        },
      })
      .then((response) => {
        // console.log(
        //   "response.data.data[0].attributes",
        //   response.data.data.attributes
        // );
        setContent(response.data.data.attributes);
      })
      .catch((error) => {
        console.error("Il y a eu une erreur !", error);
      });
  }, []);

  if (!content) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {/* <LoginModal isOpen={isOpenLogin} hide={toggleModalLogin} /> */}

      <div className="globalContainer">
        <main id="homeMain">
          <div className="mainBg">
            <div></div>
          </div>
          <div className="wrap vac">
            <h1 className="mb50">
              <span>{content.Title}</span>
              <br />
              <img className="vectorHeroAPP" src="/assets/vector_hero.png" />
            </h1>
            <div>
              {content.iphoneHome && content.iphoneHome.data && (
                <img
                  className="phoneHero"
                  src={`${contents}${content.iphoneHome.data.attributes.url}`}
                  alt={
                    content.iphoneHome.data.attributes.alternativeText ||
                    "Hero Image"
                  }
                />
              )}
              {/* <img className="phoneHero" src="/assets/iphone_home.png" /> */}
            </div>
            <h4>
              <span>{content.Subtitle}</span>
            </h4>
            <br />
            <br />
            <div>
              <img className="circleAPP" src="/assets/circle.png" />
              {content.buttonAppleStoreIMG &&
                content.buttonAppleStoreIMG.data && (
                  <a target="_blank" href={content.buttonAppleStoreURL}>
                    <img
                      className="buttonStore"
                      src={`${contents}${content.buttonAppleStoreIMG.data.attributes.url}`}
                      alt="Apple Store"
                    />
                  </a>
                )}
              {content.buttonGooglePlayIMG &&
                content.buttonGooglePlayIMG.data && (
                  <a target="_blank" href={content.buttonGooglePlayURL}>
                    <img
                      className="buttonStore"
                      src={`${contents}${content.buttonGooglePlayIMG.data.attributes.url}`}
                      alt="Google Play"
                    />
                  </a>
                )}
            </div>
          </div>
        </main>

        <section id="dashboardScreen">
          <div className="wrap">
            <div className="mb30">
              <h3 className="section-title">{content.TitlePage}</h3>
              <p className="paragraphAPP">{content.SubTitlePage}</p>
            </div>
          </div>
        </section>

        <section id="evenementsSection">
          <div className="container">
            <div className="text-content">
              <div className="icon-title">
                <img src="/assets/icon_event.png" alt="Icon" className="icon" />
                <h3>{content.LesEvenements}</h3>
              </div>
              <p className="description">{content.EvenementSub}</p>
              <ul className="features-list">
                <li>{content.Evenement1}</li>
                <li>{content.Evenement2}</li>
                <li>{content.Evenement3}</li>
              </ul>
              <p className="conclusion">{content.Evenement4}</p>
              <div className="left">
                <button className="cta-button disabled">
                  {content.EvenementButton}
                  <span className="tooltip">Bientôt disponible</span>
                </button>
              </div>
            </div>
            <div className="image-content">
              {content.EvenementImage && content.EvenementImage.data && (
                <img
                  className="phone-image"
                  src={`${contents}${content.EvenementImage.data.attributes.url}`}
                  alt={
                    content.EvenementImage.data.attributes.alternativeText ||
                    "Phone Section"
                  }
                />
              )}
            </div>
          </div>
        </section>

        <section id="ecolesSection">
          <div className="container">
            <div className="image-content">
              {content.EcoleIMG && content.EcoleIMG.data && (
                <img
                  className="phone-image"
                  src={`${contents}${content.EcoleIMG.data.attributes.url}`}
                  alt={
                    content.EcoleIMG.data.attributes.alternativeText ||
                    "Phone Section"
                  }
                />
              )}
            </div>
            <div className="text-content">
              <div className="icon-title">
                <img src="/assets/ecole_icon.png" alt="Icon" className="icon" />
                <h3>{content.EcoleTitle}</h3>
              </div>
              <p className="description">{content.EcoleDescription}</p>
              <ul className="features-list">
                <li>{content.Ecole1}</li>
                <li>{content.Ecole2}</li>
                <li>{content.Ecole3}</li>
                <li>{content.Ecole4}</li>
                <li>{content.Ecole5}</li>
              </ul>
              <p className="conclusion">{content.EcoleDescriptionEnd}</p>
              <div className="left">
                <button className="cta-button disabled">
                  {content.EcoleButton}
                  <span className="tooltip">Bientôt disponible</span>
                </button>
              </div>
            </div>
          </div>
        </section>

        <section id="professeursSection">
          <div className="container">
            <div className="text-content">
              <div className="icon-title">
                <img
                  src="/assets/social_icon.png"
                  alt="Icon"
                  className="icon"
                />
                <h3>{content.ProfTitle}</h3>
              </div>
              <p className="description">{content.ProfDescription}</p>
              <ul className="features-list">
                <li>{content.Prof1}</li>
                <li>{content.Prof2}</li>
                <li>{content.Prof3}</li>
              </ul>
              <p className="conclusion">{content.ProfEndDescription}</p>
              <div className="left">
                <button className="cta-button disabled">
                  {content.ProfButton}
                  <span className="tooltip">Bientôt disponible</span>
                </button>
              </div>
            </div>
            <div className="image-content">
              {content.ProfIMG && content.ProfIMG.data && (
                <img
                  className="phone-image"
                  src={`${contents}${content.ProfIMG.data.attributes.url}`}
                  alt={
                    content.ProfIMG.data.attributes.alternativeText ||
                    "Phone Section"
                  }
                />
              )}
            </div>
          </div>
        </section>

        <section id="highlightEvent">
          <div className="highlight-content">
            <p className="small-text">{content.SubVignette}</p>
            <h2 className="highlight-title">{content.VignetteTitle}</h2>
            <button className="highlight-button disabled">
              {content.VignetteButton}
              <span className="tooltip">Bientôt disponible</span>
            </button>
          </div>
        </section>

        <Suspense fallback={<div>{t("loading")}...</div>}>
          <Footer />
        </Suspense>
      </div>
    </>
  );
} // END HOMEPAGE

export default Application;

import { useState, useContext } from "react";
import NavBar from "./components/navBar.js";
import Menu from "./components/MenuFeed.js";
import NavDashboard from "./components/NavDashboard.js";
import HomePage from "./views/HomePage.js";
import QuiEstAnda from "./views/QuiEstAnda.js";
import Application from "./views/Application.js";
import RegisterPage from "./views/RegisterPage.js";
import Profile from "./views/Profile.js";
import Dashboard from "./views/Dashboard.js";
import Security from "./views/Security";
import NotFound from "./views/NotFound.js";
import PrivacyPage from "./views/PrivacyPage.js";
import LegalsPage from "./views/LegalsPage.js";
import Login from "./views/LoginPage.js";
import Subscription from "./views/Subscription.js";
import Rapport from "./views/Rapport.js";
import Feed from "./views/Feed.js";
import NewMention from "./views/newMention.js";
import Leads from "./views/Leads.js";
import Credits from "./views/Credits.js";
import About from "./views/About.js";
import Affiliate from "./views/Affiliate.js";
import AccountValidation from "./views/AccountValidation.js";
import Loader from "./components/Loader.js";
import ChangePassword from "./views/ChangePassword.js";
import JoinTeam from "./views/JoinTeam.js";
import BlogPost from "./views/BlogPost.js";
import Blog from "./views/Blog.js";
import ComingSoon from "./views/ComingSoon.js";

import { AuthContext } from "./context/AuthContext.js";
import AuthProvider from "./context/AuthContext.js";
import ReactGA from "react-ga4";
import mixpanel from "mixpanel-browser";

import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Navigate,
  useLocation,
} from "react-router-dom";

import { useTranslation } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Notifications
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./App.css";
import "./i18n";

// ReactGA.initialize("G-KNVY6HN96Z");
// mixpanel.init("1731048e1a6132166db1c225085f4c4a");
// ReactGA.pageview(window.location.pathname + window.location.search);

// PRIVATE USERS ROUTES
const PrivateRoute = ({ children, isAuthenticate }) => {
  const context = useContext(AuthContext);
  return context.isAuthenticate ? children : <Navigate to="/" />;
};

function AuthenticatedRoute({ element }) {
  const context = useContext(AuthContext);

  if (context.isLoading) {
    return <Loader />;
  }

  if (!context.isAuthenticate) {
    return <Navigate to="/login" />;
  }

  return element;
}

function AppRoutes() {
  const context = useContext(AuthContext);

  // Redirection conditionnelle pour les utilisateurs authentifiés
  // if (context.isAuthenticate) {
  //   return <Navigate to="/dashboard" />;
  // }

  return (
    <>
      {/* <NavBar /> */}
      {/* <Menu /> */}
      <Routes>
        {/* <Route path="/home" element={<HomePage />} /> */}
        <Route path="/" element={<HomePage />} />
        <Route path="/qui-est-anda" element={<QuiEstAnda />} />
        <Route path="/application" element={<Application />} />
        <Route path="/blog" element={<Blog />} />
        {/* <Route path="/login" element={<Login />} /> */}
        {/* <Route path="/register" element={<RegisterPage />} /> */}
        {/* <Route
          path="/account-validation/:activationToken"
          element={<AccountValidation />}
        /> */}
        {/* <Route path="/change-password" element={<ChangePassword />} />
        <Route
          path="/profile"
          element={<AuthenticatedRoute element={<Profile />} />}
        />
        <Route
          path="/dashboard"
          element={<AuthenticatedRoute element={<Dashboard />} />}
        /> */}
        <Route path="/blog/:slug" element={<BlogPost />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <NavBar />
        <ToastContainer />
        <AppRoutes />
      </AuthProvider>
    </BrowserRouter>
  );
} // END APP

export default App;

// REACT STUFF
import React, { Suspense } from "react";
import { useEffect, useState, useContext, Component } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import axios from "axios";
import RelatedArticles from "../components/RelatedArticles.js";

// AUTH CONTEXT
import { AuthContext } from "../context/AuthContext.js";

import GoogleConnect from "../components/GoogleConnect.js";

// LIBS
import { useTranslation } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import "../i18n";
// import Modal from "react-modal";

import {
  Switch,
  Button,
  Form,
  Input,
  Radio,
  Modal,
  Tooltip,
  Row,
  Col,
  Alert,
} from "antd";

//Components
const Footer = React.lazy(() => import("../components/footer"));

import Loader from "../components/Loader.js";
const Accordion = React.lazy(() => import("../components/Accordion"));
import TypingText from "../components/typingText.js";

// LIB
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// CSS STYLES
import "./HomePage.css";
const Testimonials = React.lazy(() => import("../components/Testimonials"));

function HomePage() {
  const context = useContext(AuthContext);

  const [isOpenLogin, setIsOpenLogin] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [results, setResults] = useState(null);
  const [resultsLinkedin, setResultsLinkedin] = useState(null);
  const [resultsReddit, setResultsReddit] = useState(null);
  const [resultsPinterest, setResultsPinterest] = useState(null);
  const [resultsYoutube, setResultsYoutube] = useState(null);
  const [resultsTiktok, setResultsTiktok] = useState(null);
  const [resultsInstagram, setResultsInstagram] = useState(null);
  const [resultsGoogle, setResultsGoogle] = useState(null);
  const [resultsGoogleNews, setResultsGoogleNews] = useState(null);
  const [resultsMedium, setResultsMedium] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isToggled, setIsToggled] = useState(false);
  const [isMonthly, setIsMonthly] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const [postBlog, setPostBlog] = useState([]);

  const { t, i18n } = useTranslation();

  const [content, setContent] = useState(null);
  const contents = `${process.env.REACT_APP_STRAPI_URL}`;

  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };

  const redirectToRegister = () => {
    window.location.href = "/register";
  };

  const openModalWithContent = (content) => {
    setModalContent(content);
    openModal();
  };

  const toggleModalLogin = () => {
    setIsOpenLogin(!isOpenLogin);
  };

  const handleToggle = () => {
    setIsMonthly(!isMonthly);
  };

  const formatCount = (count) => {
    if (count <= 100) {
      return count.toString();
    } else if ((count) => 1000) {
      return 100;
    }
  };

  // Récupérer les articles récents
  useEffect(() => {
    axios
      .get(
        `${contents}/api/blogs?sort=createdAt:desc&pagination[limit]=3&populate=*`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_BEARER_CONTENT}`,
          },
        }
      )
      .then((response) => {
        setPostBlog(response.data.data);
      })
      .catch((error) => {
        console.error("Erreur en récupérant les articles", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${contents}/api/home?populate=*`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_BEARER_CONTENT}`,
        },
      })
      .then((response) => {
        // console.log("response.data.data[0].attributes", response);
        setContent(response.data.data.attributes);
      })
      .catch((error) => {
        console.error("Il y a eu une erreur !", error);
      });
  }, []);

  const [isModalVisibleWaitlistHome, setIsModalVisibleWaitlistHome] =
    useState(false);
  const [selectedRole, setSelectedRole] = useState("professionnel");
  const [formData, setFormData] = useState({
    nom: "",
    prenom: "",
    email: "",
    telephone: "",
  });

  const handleRoleChangeWaitlist = (role) => {
    setSelectedRole(role);
  };

  const showModalWaitlist = () => {
    setIsModalVisibleWaitlistHome(true);
  };

  const handleOkWaitlist = () => {
    setIsModalVisibleWaitlistHome(false);
    // Handle form submission here
  };

  const handleCancelWaitlist = () => {
    setIsModalVisibleWaitlistHome(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmitWaitlist = async () => {
    // Expressions régulières pour valider l'email et le téléphone
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Validation simple d'email
    const phoneRegex = /^[0-9+\-() ]+$/; // Accepte les chiffres, "+", "-", "(", ")", et espace

    // Vérification des champs avant l'envoi
    if (!emailRegex.test(formData.email)) {
      toast.error("Veuillez entrer un email valide.");
      return;
    }

    if (!phoneRegex.test(formData.telephone)) {
      toast.error("Veuillez entrer un numéro de téléphone valide.");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/user/waitlist`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            role: selectedRole,
            nom: formData.nom,
            prenom: formData.prenom,
            email: formData.email,
            telephone: formData.telephone,
          }),
        }
      );

      const data = await response.json();
      if (response.ok) {
        toast.success("Inscription réussie !");
        // Ferme la modal après succès
        setIsModalVisibleWaitlistHome(false);
      } else {
        toast.error(`Erreur lors de l'inscription : ${data.message}`);
      }
    } catch (error) {
      toast.error("Erreur lors de l'appel à l'API.");
    }
  };

  if (!content) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {/* <LoginModal isOpen={isOpenLogin} hide={toggleModalLogin} /> */}

      <div className="globalContainer">
        <main id="homeMain">
          <div className="mainBg">
            <div></div>
          </div>
          <div className="wrap vacHome">
            <div>
              <img className="arrowHero" src="/assets/arrow_hero.png" />
              <span className="vignetteBlancheHeroHome">
                {content.heroTitle}
              </span>
            </div>
            <h1 className="mb50">
              <span
                dangerouslySetInnerHTML={{ __html: content.heroSubtitle }}
              ></span>
              <br />
              <img className="vectorHero" src="/assets/vector_hero.png" />
            </h1>
            <div>
              {content.iphoneHome && content.iphoneHome.data && (
                <img
                  className="phoneHero"
                  src={`${contents}${content.iphoneHome.data.attributes.url}`}
                  alt={
                    content.iphoneHome.data.attributes.alternativeText ||
                    "Hero Image"
                  }
                />
              )}
            </div>
            <h4>
              <span
                dangerouslySetInnerHTML={{ __html: content.description }}
              ></span>
            </h4>
            <br />
            <br />
            {/* <div className="buttonPhoneDownload">
              <img className="shineHero" src="/assets/shine_hero.png" />
              {content.buttonAppleStoreIMG &&
                content.buttonAppleStoreIMG.data && (
                  <a target="_blank" href={content.buttonAppleStore}>
                    <img
                      className="buttonStore"
                      src={`${contents}${content.buttonAppleStoreIMG.data.attributes.url}`}
                      alt="Apple Store"
                    />
                  </a>
                )}
              {content.buttonGooglePlayIMG &&
                content.buttonGooglePlayIMG.data && (
                  <a target="_blank" href={content.buttonGooglePlay}>
                    <img
                      className="buttonStore"
                      src={`${contents}${content.buttonGooglePlayIMG.data.attributes.url}`}
                      alt="Google Play"
                    />
                  </a>
                )}
            </div> */}
            <div className="buttonPhoneDownload">
              <img className="shineHero" src="/assets/shine_hero.png" />
              <button
                className="ButtonActionWaitlist"
                onClick={showModalWaitlist}
              >
                S'inscrire à la liste d'attente
              </button>
            </div>
          </div>
        </main>

        <section id="dashboardScreen">
          <div>
            <img className="arrowSection" src="/assets/arrow_section.png" />
            <span className="vignetteBlancheSection">
              {content.VignetteUnderIphone}
            </span>
          </div>
          <div className="wrapL">
            <div className="mb30">
              <h3>{content.AboutUs}</h3>
              <p
                className="paragraphHome"
                dangerouslySetInnerHTML={{ __html: content.AboutUsDescription }}
              ></p>
            </div>
          </div>
        </section>

        <Modal
          title={null}
          open={isModalVisibleWaitlistHome}
          onOk={handleOkWaitlist}
          onCancel={handleCancelWaitlist}
          footer={[]}
          className="custom-modal"
          width={window.innerWidth < 768 ? "100%" : 1200} // Adapte la largeur sur mobile
        >
          <Row>
            {/* Colonne pour le texte et le formulaire */}
            <Col xs={24} md={12}>
              <div className="modal-content">
                <h2 className="modal-title">
                  Rejoignez la liste d'attente pour être les premiers informés !
                </h2>
                <p className="modal-description">
                  En attendant le lancement de notre application, inscrivez-vous
                  à notre liste d'attente pour recevoir toutes les informations
                  en avant-première. Que vous soyez danseur ou organisateur,
                  nous vous tiendrons au courant dès que nous serons prêts à
                  vous accueillir. Ne manquez pas cette opportunité de faire
                  partie des premiers à découvrir notre plateforme !
                </p>

                <div className="role-selection-container">
                  <Button
                    className={`role-button ${
                      selectedRole === "professionnel" ? "selected" : ""
                    }`}
                    onClick={() => handleRoleChangeWaitlist("professionnel")}
                  >
                    Professionnel
                  </Button>
                  <Button
                    className={`role-button ${
                      selectedRole === "danseur" ? "selected" : ""
                    }`}
                    onClick={() => handleRoleChangeWaitlist("danseur")}
                  >
                    Danseur
                  </Button>
                </div>

                <Row gutter={16} style={{ marginTop: "10px" }}>
                  <Col xs={24} md={12}>
                    <Input
                      placeholder="Nom"
                      name="nom"
                      value={formData.nom}
                      onChange={handleInputChange}
                      className="custom-input"
                    />
                  </Col>
                  <Col xs={24} md={12}>
                    <Input
                      placeholder="Prénom"
                      name="prenom"
                      value={formData.prenom}
                      onChange={handleInputChange}
                      className="custom-input"
                    />
                  </Col>
                </Row>

                <Input
                  placeholder="Adresse e-mail"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="custom-input"
                  style={{ marginTop: "10px" }}
                />
                <Input
                  placeholder="N° de téléphone"
                  name="telephone"
                  value={formData.telephone}
                  onChange={handleInputChange}
                  className="custom-input"
                  style={{ marginTop: "10px" }}
                />
                <div id="buttonWaitlist">
                  <button
                    className="ButtonActionWaitlist"
                    onClick={handleSubmitWaitlist}
                  >
                    <a
                      key="submit"
                      className=""
                      style={{
                        color: "white",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                      }}
                    >
                      Je m'inscris à la liste d'attente
                    </a>
                  </button>
                </div>
              </div>
            </Col>

            {/* Colonne pour l'image */}
            <Col xs={0} md={12}>
              <div className="modal-image-container">
                <img
                  src="/images/wait_list.jpg" // Remplace par le chemin correct de ton image
                  alt="Image"
                  className="modal-image"
                />
              </div>
            </Col>
          </Row>
        </Modal>

        <section id="commentCaMarche">
          <div className="wrapL">
            {/* <div className="imageBackground"></div> */}
            <div className="commentCaMarche-content">
              <div className="phone-container">
                {content.HowItWorkIMG && content.HowItWorkIMG.data && (
                  <img
                    className="phoneSection"
                    src={`${contents}${content.HowItWorkIMG.data.attributes.url}`}
                    alt={
                      content.HowItWorkIMG.data.attributes.alternativeText ||
                      "Phone Section"
                    }
                  />
                )}
              </div>
              <div className="text-container">
                <h3>{content.HowItWork}</h3>
                <ul
                  dangerouslySetInnerHTML={{
                    __html: content.HowItWorkDescription,
                  }}
                ></ul>
              </div>
            </div>
          </div>
        </section>

        <section id="features">
          <div className="wrapL">
            <div className="mt30 mb30">
              <h3>{content.DiscoverApp}</h3>
            </div>
            <div className="parentContainer">
              {/* Image pour "Les événements" */}
              <div className="feature-item">
                {content.eventTitleIMG && content.eventTitleIMG.data && (
                  <img
                    loading="lazy"
                    src={`${contents}${content.eventTitleIMG.data.attributes.url}`}
                    alt="Les événements"
                  />
                )}
                <div className="feature-label">{content.eventsTitle}</div>
              </div>

              {/* Image pour "Les écoles de danse" */}
              <div className="feature-item">
                {content.schoolTitleIMG && content.schoolTitleIMG.data && (
                  <img
                    loading="lazy"
                    src={`${contents}${content.schoolTitleIMG.data.attributes.url}`}
                    alt="Les écoles de danse"
                  />
                )}
                <div className="feature-label">{content.schoolTitle}</div>
              </div>

              {/* Image pour "Les professeurs" */}
              <div className="feature-item">
                {content.teacherTitleIMG && content.teacherTitleIMG.data && (
                  <img
                    loading="lazy"
                    src={`${contents}${content.teacherTitleIMG.data.attributes.url}`}
                    alt="Les professeurs"
                  />
                )}
                <div className="feature-label">{content.teacherTitle}</div>
              </div>
            </div>
          </div>
        </section>

        <section id="highlightEvent">
          <div className="highlight-content">
            <p className="small-text">{content.SubtitleVignette}</p>
            <h2 className="highlight-title">{content.TitleVignette}</h2>
            <button className="highlight-button disabled">
              {content.ButtonVignette}
              <span className="tooltip">Bientôt disponible</span>
            </button>
          </div>
        </section>

        <section id="blogSection">
          <div className="wrapL columnDirection">
            <h3 className="section-title">{content.BlogTitle}</h3>
            <p className="section-subtitle">{content.SubTitleBlog}</p>
          </div>
          <RelatedArticles articles={postBlog} />
        </section>

        <Suspense fallback={<div>{t("loading")}...</div>}>
          <section id="FAQ">
            <div className="wrap FAQ">
              <div className="tac mb30">
                <h3 className="section-title">{content.FAQ}</h3>
                <p className="section-subtitle">{content.SubtitleFAQ}</p>
              </div>
              <div className="accordionDiv">
                <Accordion question={content.FAQ1} answer={content.FAQ1A} />
                <Accordion question={content.FAQ2} answer={content.FAQ2A} />
                <Accordion question={content.FAQ3} answer={content.FAQ3A} />
                <Accordion question={content.FAQ4} answer={content.FAQ4A} />
                <Accordion question={content.FAQ5} answer={content.FAQ5A} />
                <Accordion question={content.FAQ6} answer={content.FAQ6A} />
              </div>
            </div>
          </section>
        </Suspense>

        <Suspense fallback={<div>{t("loading")}...</div>}>
          <Footer />
        </Suspense>
      </div>
    </>
  );
} // END HOMEPAGE

export default HomePage;

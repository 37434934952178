import React from "react";
import { Link } from "react-router-dom";
import "./OurBlog.css";

function OurBlog({ articles }) {
  if (!articles || articles.length === 0) {
    return <div>Loading...</div>;
  }
  return (
    <div className="">
      <div className="blog-layout">
        {/* Premier article plus grand */}
        <div className="blog-card-large">
          {articles[0].attributes.Image && (
            <img
              src={`${process.env.REACT_APP_STRAPI_URL}${articles[0].attributes.Image.data.attributes.url}`}
              alt={articles[0].attributes.Titre}
              className="blog-image-large"
            />
          )}
          <h3 className="blog-titleHome">{articles[0].attributes.Titre}</h3>
          <p className="blog-excerpt">
            {articles[0].attributes.SousTitre ||
              articles[0].attributes.Contenu.substring(0, 100)}
            ...
          </p>
          <div className="right">
            <Link
              to={`/blog/${articles[0].attributes.URL}`}
              className="blog-link"
            >
              Voir l'article <span>→</span>
            </Link>
          </div>
        </div>

        {/* Les deux autres articles */}
        <div className="blog-column">
          {articles.slice(1, 3).map((article) => (
            <div key={article.id} className="blog-card-small">
              {article.attributes.Image && (
                <img
                  src={`${process.env.REACT_APP_STRAPI_URL}${article.attributes.Image.data.attributes.url}`}
                  alt={article.attributes.Titre}
                  className="blog-image-small"
                />
              )}
              <h3 className="blog-titleHome">{article.attributes.Titre}</h3>
              <p className="blog-excerpt">
                {article.attributes.SousTitre ||
                  article.attributes.Contenu.substring(0, 100)}
                ...
              </p>
              <div className="right">
                <Link
                  to={`/blog/${article.attributes.URL}`}
                  className="blog-link"
                >
                  Voir l'article <span>→</span>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default OurBlog;

// REACT STUFF
import React, { Suspense } from "react";
import { useEffect, useState, useContext, Component } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import ReactMarkdown from "react-markdown";
import axios from "axios";
import RelatedArticles from "../components/RelatedArticles.js";

// AUTH CONTEXT
import { AuthContext } from "../context/AuthContext.js";

import GoogleConnect from "../components/GoogleConnect.js";

// LIBS
import { useTranslation } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import "../i18n.js";
import Modal from "react-modal";

//Components
const Footer = React.lazy(() => import("../components/footer.js"));

import Loader from "../components/Loader.js";

// CSS STYLES
import "./BlogPost.css";

function BlogPost() {
  const context = useContext(AuthContext);
  const [content, setContent] = useState(null);
  const [relatedPosts, setRelatedPosts] = useState([]);

  const contents = `${process.env.REACT_APP_STRAPI_URL}`;
  const { slug } = useParams();

  useEffect(() => {
    axios
      .get(`${contents}/api/blogs?filters[URL][$eq]=${slug}&populate=*`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_BEARER_CONTENT}`,
        },
      })
      .then((response) => {
        // Récupère les données du blog
        setContent(response.data.data[0].attributes);
        // console.log("Response Content:", response.data.data[0].attributes);
      })
      .catch((error) => {
        console.error("Il y a eu une erreur !", error);
      });
  }, [slug]);

  // Récupérer les articles récents
  useEffect(() => {
    axios
      .get(
        `${contents}/api/blogs?sort=createdAt:desc&pagination[limit]=3&populate=*`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_BEARER_CONTENT}`,
          },
        }
      )
      .then((response) => {
        setRelatedPosts(response.data.data); // Articles à lire aussi
      })
      .catch((error) => {
        console.error("Erreur en récupérant les articles", error);
      });
  }, []);

  if (!content) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="globalContainer">
        <div className="blog-post-container">
          <header className="blog-header">
            {content.Image && (
              <img
                className="blog-header-image"
                src={`${contents}${content.Image.data.attributes.url}`}
                alt={content.Titre}
              />
            )}
          </header>

          <article className="blog-content">
            <article className="blog-content">
              <div className="">
                <span className="blog-date">
                  Publié le {new Date(content.Date).toLocaleDateString()}
                </span>
                <h1 className="blog-title">{content.Titre}</h1>
                <p className="blog-subtitle">{content.SousTitre}</p>
                <hr className="ligne" />
                <br />
              </div>
              <ReactMarkdown>{content.content}</ReactMarkdown>
            </article>
          </article>

          <RelatedArticles articles={relatedPosts} />
        </div>
      </div>

      <Footer />
    </>
  );
}

export default BlogPost;
